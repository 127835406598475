import  styles from './Error.module.css';

const Error = () =>{

    return(
      <>
        <h2 className={styles.text_primary}>Oops!</h2>
        <h3 className={styles.text_secondary}>404 - PAGE NOT FOUND</h3>
        <p className={styles.text_normal}>The page you are looking for might have been removed had its name changed or is temporarily unavailable</p>
      </>  
    );
}

export default Error;